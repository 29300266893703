html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #e8f5e9; /* Light green background */
}

.App {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  max-width: 50%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.App-header {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 90%;
  margin: auto;
}

h1 {
  font-size: 3vw;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 20px;
  color: #2e7d32; /* Dark green color */
}

p {
  font-size: 1.5vw;
  line-height: 1.6;
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
  font-size: 1.5vw;
  margin: 10px;
  display: inline-block;
}

a:hover {
  text-decoration: underline;
}

.contact-info, .store-hours {
  margin-top: 20px;
}

.map-embed {
  margin-top: 20px;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.map-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App-logo {
    max-width: 70%;
  }

  .App-header {
    padding: 20px;
  }

  h1 {
    font-size: 5vw;
  }

  p, a {
    font-size: 4vw;
  }
}
